/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Sector {
    UNKNOWN = 'unknown',
    RETAIL = 'retail',
    OFFICE = 'office',
    INDUSTRIAL = 'industrial',
    CHILDCARE = 'childcare',
    FUEL = 'fuel',
}
